<template>
  <div class="page_">
    <!-- <div class="update">
		升级中，敬请期待......
	</div> -->
    <div>
      <div class="navbar_">
        <video
          controls
          style="width: 100%; height: 100vh; object-fit: fill"
          poster="../../assets/medilink/mainbg.png"
          v-show="playVideo"
        >
          <source
            :src="require('../../assets/medilink/demo.mp4')"
            type="video/mp4"
          />
        </video>
        <div class="main_" v-show="!playVideo">
          <div class="dimback">
            <img
              class="dim"
              src="../../assets/medilink/indexfirst.jpg"
              alt=""
            />
          </div>
          <div class="text_">
            <p id="top">
              {{ $t("HOME[1]") || "致力于成为优质的健康险解决方案提供商" }}
            </p>
            <!-- <span class="play_"></span> -->
          </div>
          <p class="text_1">@2005</p>
          <p class="phone_">
            <span class="phone_1"></span>
            <span>{{ $t("LOGIN[5]") || "服务热线" }}<br />400 880 8820</span>
          </p>
          <div
            class="wechat_"
            @mouseenter="visible('qrCode1')"
            @mouseleave="invisible('qrCode1')"
          >
            <span class="saoma_"></span>
            <span
              >{{ $t("AMG[18]") || "中间带在线" }}<br />{{
                $t("LOGIN[9]") || "会员/医疗机构/合作伙伴登录"
              }}</span
            >
            <div class="wechat_saomao_none_ " v-show="showCode1">
              <img src="../../assets/medilink/saoma.png" alt="" />
            </div>
          </div>
          <div
            class="wechat_1_"
            @mouseenter="visible('qrCode2')"
            @mouseleave="invisible('qrCode2')"
          >
            <span class="saoma_"></span>
            <span
              >{{ $t("HOME[28]") || "中间带24小时" }}<br />{{
                $t("HOME[29]") || "中间带官微，点击关注"
              }}</span
            >
            <div class="wechat_saomao_none_" v-show="showCode2">
              <img src="../../assets/medilink/erweima24.jpeg" alt="" />
            </div>
          </div>
          <div
            class="wechat_2_"
            @mouseenter="visible('qrCode3')"
            @mouseleave="invisible('qrCode3')"
          >
            <span class="saoma_"></span>
            <span
              >MediLink Global 中间带<br />{{
                $t("HOME[30]") || "健康问题，一步搞定"
              }}</span
            >
            <div class="wechat_saomao_none_" v-show="showCode3">
              <img src="../../assets/medilink/ma3.jpg" alt="" />
            </div>
          </div>
        </div>
        <navbar :showHeader="showHeader" :nowIndex="1"></navbar>
      </div>
      <div class="block_">
        <div class="bgtit_ animate">
          COMPANY PROFILE
          <p class="flex_ tit_">
            <span class="drop"></span>{{ $t("HOME[2]") || "不一样的中间带"
            }}<span class="drop"></span>
          </p>
        </div>
        <p class="fs_11 animate">
          {{ $t("HOME[3]") || "健康险第三方管理服务供应商" }}
        </p>
        <div class="con_ w_74">
          <div class="con1_ animate">
            <img src="../../assets/medilink/about.jpg" alt="" />
            <div class="content_">
              <div class="left_">
                <p class="tit_">
                  {{
                    $t("about[5]") || "致力于成为中国最好的健康险解决方案提供商"
                  }}
                </p>
                <p class="des_">
                  {{
                    $t("about[7]") ||
                      `中间带（北京）技术服务有限公司是一家专门从事健康保险第三方管理的专业且独立的服务公司，最早起源于拥有业内技术领先的ECCS核心系统的马来西亚MediLink-Global公司，2019年11月加入南燕集团并成为其成员企业。\n公司成立于2005年，成立伊始便专注客户服务，提升核心技术，创造数据价值，确保客户数据安全。公司秉承独立、专业、客户利益至上的宗旨，为中外保险公司、再保险公司、保险代理机构、保险经纪公司和大型自保企业提供健康保险第三方管理服务、直付医疗网络以及企业健康服务等，在市场上拥有良好的口碑和领先的品牌。\n合作几十家保险公司，服务会员超过15万，大型企业超过3200家。`
                  }}
                </p>
                <div class="flex_">
                  <div class="flex_i">
                    <span class="bold_"
                      ><span class="drop"></span
                      >{{ $t("about[8]") || "使命" }}</span
                    >
                    <span>{{
                      $t("about[9]") ||
                        `通过技术创新与资源整合，\n帮助医疗保险体系实现最大价值。`
                    }}</span>
                  </div>
                  <div class="flex_i">
                    <span class="bold_"
                      ><span class="drop"></span
                      >{{ $t("about[10]") || "愿景" }}</span
                    >
                    <span>{{
                      $t("about[11]") || "成为TPA行业的领导者与标准制定者"
                    }}</span>
                  </div>
                  <div class="flex_i">
                    <span class="bold_"
                      ><span class="drop"></span
                      >{{ $t("about[8]") || "价值观" }}</span
                    >
                    <span>{{
                      $t("about1[0]") ||
                        "保证数据处理的安全性与专业性；为合作伙伴带来更大的价值；为会员提供高品质的服务"
                    }}</span>
                  </div>
                </div>
                <p>
                  <router-link :to="{ path: '/MediLink/about' }"
                    ><span>{{ $t("HOME[31]") || "更多" }}</span
                    ><span class="jiantou_"></span
                  ></router-link>
                </p>
              </div>
              <div class="right_"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="block_">
        <div class="bgtit_ animate">
          NETWORK HOSPITAL
          <p class="flex_ tit_">
            <span class="drop"></span>{{ $t("HOME[27]") || "覆盖全球的网络医院"
            }}<span class="drop"></span>
          </p>
        </div>
        <!-- <p class="fs_11 animate">包含150个国家地区</p> -->
        <hospital-area></hospital-area>
      </div>
      <div class="block_">
        <div class="bgtit_ animate">
          NETWORK HOSPITAL
          <p class="flex_ tit_">
            <span class="drop"></span
            >{{ $t("HOME[13]") || "大陆区域-直付网络医院"
            }}<span class="drop"></span>
          </p>
        </div>
        <p class="fs_11 animate">
          <span style="color: #00A63C;font-weight:bold;">2774+</span
          >{{ $t("HOME[22]") || "家网络机构，覆盖"
          }}<span style="color: #00A63C;font-weight:bold;">298</span
          >{{ $t("HOME[14]") || "个省市" }}
        </p>
        <div class="map_">
          <div :style="{ height: '800px', width: '100%' }" ref="myEchart"></div>
        </div>
        <a href="" class="join_ animate" style="width:260px"
          ><router-link :to="{ path: '/MediLink/hospital/mainland' }">{{
            $t("HOME[15]") || "查看直付医疗机构 | 加入我们的网络"
          }}</router-link></a
        >
      </div>
      <div class="block_">
        <div class="bgtit_ animate">
          PRODUCTS AND SERVICES
          <p class="flex_ tit_">
            <span class="drop"></span>{{ $t("HOME[16]") || "产品与服务"
            }}<span class="drop"></span>
          </p>
        </div>
        <p class="fs_11 animate">
          {{ $t("HOME[17]") || "提供全面的高端健康险第三方管理服务" }}
        </p>
        <div class="con_2">
          <a class="con_item">
            <img src="../../assets/medilink/img1.png" alt="" />
            <span>{{ $t("HOME[18]") || "第三方管理服务" }}</span>
            <router-link :to="{ path: '/MediLink/product' }">
              <div class="mask_">
                <span>{{ $t("HOME[18]") || "第三方管理服务" }}</span>
              </div>
            </router-link>
          </a>

          <a class="con_item">
            <img src="../../assets/medilink/img2.png" alt="" />
            <span>{{ $t("HOME[23]") || "会员服务" }}</span>
            <router-link :to="{ path: '/MediLink/memberService' }">
              <div class="mask_">
                <span>{{ $t("HOME[23]") || "会员服务" }}</span>
              </div>
            </router-link>
          </a>
          <!-- <a class="con_item">
	        <img src="../../assets/medilink/img4.png" alt="">
	        <span>增值服务</span>
	        <router-link  :to="{path:'/MediLink/incrementService'}">
	        <div class="mask_">
	          <span>增值服务</span>
	        </div>
	        </router-link>
	      </a>
	      <a class="con_item">
	        <img src="../../assets/medilink/img3.png" alt="">
	        <span>紧急医疗援助</span>
	        <router-link  :to="{path:'/MediLink/rescueService'}">
	        <div class="mask_">
	          <span>紧急医疗援助</span>
	        </div>
	        </router-link>
	      </a> -->
          <a class="con_item">
            <img src="../../assets/medilink/img3.png" alt="" />
            <span>{{ $t("HOME[24]") || "企业健康服务" }}</span>
            <router-link :to="{ path: '/MediLink/health' }">
              <div class="mask_">
                <span>{{ $t("HOME[24]") || "企业健康服务" }}</span>
              </div>
            </router-link>
          </a>
          <a class="con_item">
            <img src="../../assets/medilink/health.jpg" alt="" />
            <span>{{ $t("HOME[25]") || "网络机构查询" }}</span>
            <router-link :to="{ path: '/MediLink/hospital/mainland' }">
              <div class="mask_">
                <span>{{ $t("HOME[25]") || "网络机构查询" }}</span>
              </div>
            </router-link>
          </a>
        </div>
      </div>
      <div class="block_" style="margin-bottom:78px;">
        <div class="bgtit_ animate">
          PARTNER
          <p class="flex_ tit_">
            <span class="drop"></span>{{ $t("HOME[19]") || "部分合作伙伴"
            }}<span class="drop"></span>
          </p>
        </div>
        <p class="fs_11 animate">
          {{
            $t("HOME[20]") ||
              "服务几十家保险客户，涵盖了国内知名的保险公司及外资独资保险公司"
          }}
        </p>
        <div class="con_4">
          <div class="con_item1" v-for="item in logoList">
            <img :src="item" alt="" />
          </div>
        </div>
        <!-- <a href="" class="join_ animate"><router-link :to="{path:'/MediLink/about',query:{loc:5}}">更多伙伴</router-link></a> -->
      </div>
      <footerbar></footerbar>
      <div class="fixbg1" v-if="isLoading">
        <a-spin />
      </div>
      <sideBar v-show="showBar"></sideBar>
      <!-- <div class="news_"  v-show="showNews">
	    <span class="close_" @click="showNews=false"></span>
	    <p style="padding-bottom:10px;font-weight:bold;font-size:18px;text-align:center;text-indent:0;">重要通知</p>
	    <p>尊敬的中间带客户及合作伙伴，新年好！我们的服务热线4008808820因电信升级调整将在2月23日18:00至2月27日18:00停止使用，不使用该号码的用户不受影响。在这期间请您拨打备用线路021-22209633。我们的客服将一如既往的为您提供24小时专业服务。给您带来的不便我们深表歉意！感谢您的理解！</p>
	    <p style="margin-top: 10px;">Dear MediLink Members and Partners, kindly be informed that our service hotline 4008808820 will be under telecom upgrading from 18:00 p.m. Feb. 23rd to 18:00 p.m. Feb. 27th. Other service numbers work well. During this period, please call 021-22209633. Our customer service is always here with you, 24 hour 7 days. Thank you for your kind understanding and cooperation. We are sorry for the inconvenience caused!</p><br />
	    <p style="text-align:right">MediLink-Global Group</p>
	    <p style="text-align:right">2021.02.22</p>
	  </div> -->
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import footerbar from "./components/footer.vue";
import navbar from "./components/navbar.vue";
import sideBar from "./components/sideBar.vue";
import hospitalArea from "./components/hospitalArea";
import "../../../node_modules/echarts/map/js/china.js"; // 引入中国地图数据

import { log } from "util";
export default {
  data() {
    return {
      showCode1: false,
      showCode2: false,
      showCode3: false,
      className: "wechat_saomao_none_",
      playVideo: false,
      chart: null,
      logoList: [
        require("../../assets/medilink/logo1.png"),
        require("../../assets/medilink/logo2.png"),
        require("../../assets/medilink/logo3.png"),
        require("../../assets/medilink/logo4.png"),
        require("../../assets/medilink/logo5.png"),
        require("../../assets/medilink/logo6.png"),
        require("../../assets/medilink/logo7.png"),
        require("../../assets/medilink/logo8.png"),
        require("../../assets/medilink/logo9.png"),
        require("../../assets/medilink/logo10.png"),
        require("../../assets/medilink/logo11.png"),
        require("../../assets/medilink/logo12.png"),
        require("../../assets/medilink/logo13.png"),
        require("../../assets/medilink/logo14.png"),
        require("../../assets/medilink/logo15.png")
      ],
      data: [
        { name: "北京", value: "418" },
        { name: "上海", value: "256" },
        { name: "广东", value: "259" },
        { name: "江苏", value: "183" },
        { name: "浙江", value: "174" },
        { name: "山东", value: "123" },
        { name: "黑龙江", value: "106" },
        { name: "河南", value: "106" },
        { name: "四川", value: "113" },
        { name: "辽宁", value: "101" },
        { name: "陕西", value: "83" },
        { name: "山西", value: "76" },
        { name: "湖北", value: "78" },
        { name: "湖南", value: "68" },
        { name: "广西", value: "66" },
        { name: "天津", value: "66" },
        { name: "河北", value: "64" },
        { name: "福建", value: "65" },
        { name: "江西", value: "58" },
        { name: "安徽", value: "58" },
        { name: "内蒙古", value: "44" },
        { name: "云南", value: "42" },
        { name: "吉林", value: "42" },
        { name: "贵州", value: "42" },
        { name: "重庆", value: "37" },
        { name: "甘肃", value: "21" },
        { name: "青海", value: "7" },
        { name: "海南", value: "7" },
        { name: "新疆", value: "5" },
        { name: "宁夏", value: "5" },
        { name: "西藏", value: "1" }
      ],
      showHeader: false,
      height: 0,
      isLoading: false,
      showBar: false,
      showNews: true
    };
  },
  components: {
    footerbar,
    navbar,
    sideBar,
    hospitalArea
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  created() {
    let origin = window.location.origin;
    if (origin === "https://provider.medilink-global.com.cn") {
      this.$router.push({
        path: "/login"
      });
    }
  },
  mounted() {
    this.isShowAgent();
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
    this.data = this.data.map(item => {
      item.value = parseInt(item.value);
      return item;
    });
    // this.fetchData();
    this.chinaConfigure();
    window.onscroll = () => {
      this.handleScroll();
    };
    this.startAnimation();
    // this.closeNews()
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$apis.getProviderListToWeb().then(res => {
        this.isLoading = false;
        if (res.data.returnCode === "0000") {
          res.data.content.map(item => {
            let dataItem = {
              name: item.state.substring(0, item.state.length - 1),
              value: parseInt(item.provideCount)
            };
            this.data.push(dataItem);
          });
          this.chinaConfigure();
        }
      });
    },
    chinaConfigure() {
      const langArr = [
        6,
        28,
        9,
        19,
        35,
        25,
        15,
        14,
        29,
        21,
        26,
        26,
        16,
        17,
        10,
        30,
        13,
        7,
        20,
        5,
        22,
        32,
        18,
        11,
        34,
        8,
        24,
        12,
        31,
        23,
        38
      ];
      const self = this;
      let myChart = echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置
      window.onresize = myChart.resize;
      myChart.setOption({
        // 进行相关配置
        backgroundColor: "#fff",
        tooltip: {
          trigger: "item",
          enterable: true,
          confine: true,
          alwaysShowContent: false,
          textStyle: {
            color: "#fff"
          },
          backgroundColor: "transparent",
          position: function(point) {
            return [point[0] + 5, point[1] + 5];
          },
          formatter: function(params, ticket, callback) {
            let name = params.name;
            const index = self.data.findIndex(item => item.name == params.name);
            const EN = self.$i18n.locale == "en-us";
            if (EN) {
              name = self.$t(`ML[${langArr[index]}]`);
            }
            let html;
            if (!params.value) {
              return "";
            } else {
              html =
                "<div class='label_p' style='display:flex;align-items:center;color:#fff;border-radius:17px;background:rgba(0,0,0,0.8);padding:10px;'><div class='label_l' style='font-size:20px;font-weight:bold;margin-right: 10px;'><span class='drop' style=' width:7px;height:7px;border-radius:4px;background-color:#00A63C;display:inline-block;margin-right:5px;'></span>" +
                name +
                "</div><div class='label_r' style='font-size:20px;font-weight:bold;line-height:20px;'>" +
                params.value +
                "+" +
                `<br /><span class='gray_' style='font-size:14px;'>${
                  EN ? "" : "已开通网络医院机构"
                }</span></div></div>`;
            }
            return html;
          }
        },
        visualMap: {
          type: "piecewise",
          splitNumber: 4,
          pieces: [
            { min: 0, max: 100, label: "1-50+", color: "#E5F6EB" },
            { min: 100, max: 200, label: "50-100+", color: "#B2E4C4" },
            { min: 200, max: 300, label: "100-200+", color: "#7FD29D" },
            { min: 300, label: "200-400+", color: "#00A63C" }
          ],
          align: "left",
          showLabel: true,
          itemGap: 10,
          itemWidth: 20,
          itemHeight: 20,
          right: "10%",
          bottom: 20,
          hoverLink: true
        },
        geo: {
          // 这个是重点配置区
          map: "china", // 表示中国地图
          roam: false,
          label: {
            normal: {
              show: true, // 是否显示对应地名
              color: "#fff"
            },
            emphasis: {
              color: "rgba(255, 255,255,1)"
            }
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(255, 255,255,1)",
              areaColor: "#ddd",
              color: "#fff"
            },
            emphasis: {
              areaColor: "#00A63C",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "transparent",
              color: "rgba(255, 255,255,1)"
            }
          }
        },
        series: [
          {
            type: "scatter",
            coordinateSystem: "geo" // 对应上方配置
          },
          {
            type: "map",
            geoIndex: 0,
            data: this.data
          }
        ]
      });
    },
    handleScroll(e) {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      this.height = top;
      this.startAnimation();
    },
    visible(data) {
      if (data === "qrCode1") {
        this.showCode1 = true;
      } else if (data === "qrCode2") {
        this.showCode2 = true;
      } else if (data === "qrCode3") {
        this.showCode3 = true;
      }
    },
    invisible(data) {
      if (data === "qrCode1") {
        this.showCode1 = false;
      } else if (data === "qrCode2") {
        this.showCode2 = false;
      } else if (data === "qrCode3") {
        this.showCode3 = false;
      }
    },
    startAnimation() {
      let elementArr = document.querySelectorAll(".animate");
      let bodyHeight = document.body.clientHeight;
      let heightArr = [];
      for (var i = 0; i < elementArr.length; i++) {
        heightArr.push(elementArr[i].offsetTop + 100);
      }
      for (var j = 0; j < heightArr.length; j++) {
        if (
          heightArr[j] >= this.height &&
          heightArr[j] < this.height + bodyHeight
        ) {
          elementArr[j].classList.add("slideUpIn60");
        }
      }
    },
    isShowAgent() {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        window.location.href = "http://www.medilink-global.com.cn/gwm/MediLink";
      }
    },
    closeNews() {
      let st = setTimeout(() => {
        this.showNews = false;
        clearTimeout(st);
      }, 10000);
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true;
          if (newHeight > oldHeight) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        } else {
          this.showHeader = false;
          this.showBar = false;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.update {
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}
.page_ {
  padding: 0;
  background-color: #fff;
  p {
    margin: 0;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    video {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
    .main_ {
      height: 100vh;
      width: 100%;
      position: relative;
      color: #fff;
      .wechat_saomao_ {
        display: block;
        z-index: 9;
        position: absolute;
        top: 130px;
        right: 228px;
        width: 300px;
        height: 300px;
      }
      .wechat_saomao_none_ {
        z-index: 9;
        position: absolute;
        top: -220px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 200px;
        height: 200px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .wechat_erweima24_ {
        display: none;
        z-index: 9;
        position: absolute;
        top: -220px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 200px;
        height: 200px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .dimback {
        background-color: #122144;
        .dim {
          height: 100vh;
          width: 100%;
          object-fit: cover;
          opacity: 0.5;
          filter: alpha(opacity=50);
        }
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 37px;
        text-align: center;
        .play_ {
          display: inline-block;
          width: 44px;
          height: 44px;
          background: url(../../assets/medilink/play.png) center center
            no-repeat;
          background-size: 44px 44px;
          margin: 20px auto 0;
        }
      }
      .text_1 {
        position: absolute;
        left: 36px;
        bottom: 5vh;
        font-size: 14px;
      }
      .phone_ {
        position: absolute;
        right: 757px;
        bottom: 5vh;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid white;
        height: 60px;
        width: 200px;
        font-size: 16px;
        line-height: 18px;
        span {
          font-size: 14px;
        }
      }
      .wechat_ {
        position: absolute;
        font-size: 14px;
        right: 496px;
        bottom: 5vh;
        line-height: 18px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid white;
        height: 60px;
        width: 235px;
      }
      .wechat_1_ {
        position: absolute;
        font-size: 14px;
        right: 272px;
        bottom: 5vh;
        line-height: 18px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid white;
        height: 60px;
        width: 200px;
      }
      .wechat_2_ {
        position: absolute;
        font-size: 14px;
        right: 46px;
        bottom: 5vh;
        line-height: 18px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid white;
        height: 60px;
        width: 200px;
      }
      .phone_1 {
        display: inline-block;
        width: 29px;
        min-width: 29px;
        height: 29px;
        background: url(../../assets/medilink/phone1.png) center center
          no-repeat;
        background-size: 29px 29px;
        margin-right: 10px;
      }
      .saoma_ {
        display: inline-block;
        min-width: 29px;
        width: 29px;
        height: 29px;
        background: url(../../assets/medilink/saoma1.png) center center
          no-repeat;
        background-size: 29px 29px;
        margin-right: 10px;
      }
    }
  }
  .block_ {
    width: 100%;
    color: #000000;
    font-size: 14px;
    p {
      text-align: center;
      &.fs_11 {
        font-size: 14px;
      }
    }
    .flex_ {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > .tit_ {
      font-size: 20px;
      font-weight: bold;
      margin-top: 115px;
      margin-bottom: 16px;
    }
    .drop {
      width: 4px;
      height: 4px;
      background-color: #00a63c;
      display: inline-block;
      margin: 0 16px;
    }
    .map_ {
      width: 100%;
    }
    .map_w {
      margin-top: 110px;
      position: relative;
      background-color: #fbfbfb;
      height: 743px;
      img {
        width: 100%;
        height: auto;
      }
      .mapcon_ {
        padding: 175px 0 105px 0;
        position: absolute;
        width: 1000px;
        bottom: 5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        img {
          width: 100%;
          height: auto;
        }

        .loc_i {
          position: absolute;
          left: 0;
          bottom: 0;
          display: flex;
          &.loc1_ {
            left: -180px;
            top: 294px;
            .line_ {
              align-items: center;
              height: 110px;
              img {
                height: 1px;
                width: 90px;
              }
            }
          }
          &.loc2_ {
            left: -120px;
            top: 435px;
            z-index: 10;
            .line_ {
              align-items: center;
              height: 110px;
              img {
                height: 1px;
                width: 380px;
              }
            }
          }
          &.loc3_ {
            flex-direction: column;
            left: 430px;
            top: 83px;
            z-index: 9;
            .line_ {
              flex-direction: column;
              align-items: center;
              width: 100%;
              .drop {
                margin: 10px 0 0 0;
              }
              img {
                width: 1px;
                height: 120px;
              }
            }
          }
          &.loc4_ {
            left: 720px;
            top: 315px;
            z-index: 9;
            .line_ {
              align-items: center;
              height: 110px;
              margin-right: 10px;
              .drop {
                margin: 0;
              }
              img {
                height: 1px;
                width: 165px;
              }
            }
          }
          &.loc5_ {
            left: 464px;
            top: 405px;
            z-index: 10;
            flex-direction: column;
            .line_ {
              flex-direction: column;
              align-items: center;
              width: 100%;
              .drop {
                margin: 0 0 10px 0;
              }
              img {
                width: 1px;
                height: 200px;
              }
            }
          }
          .box_ {
            background-color: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.4);
            color: #000;
            padding: 20px 20px;
            background: rgba(255, 255, 255, 1);
            width: 250px;
            height: 110px;
            box-shadow: 0px 4px 25px 0px rgba(232, 232, 232, 0.5);
            &:hover {
              box-shadow: 0px 4px 25px 0px rgba(232, 232, 232, 1);
            }
            p {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .drop {
                background-color: #00a63c;
                border-radius: 100%;
                margin: 0 8px 0 0;
                width: 6px;
                height: 6px;
              }
              &:first-child {
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 1px;
              }
              &:last-child {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 1px;
                margin-top: 10px;
              }
            }
          }
          .line_ {
            display: flex;
            .drop {
              width: 9px;
              height: 9px;
              border-radius: 10px;
              margin: 0 0 0 10px;
            }
          }
        }
        .text_ {
          position: absolute;
          right: 0;
          bottom: 15px;
          display: flex;
          align-items: center;
          font-weight: bold;
          .drop {
            border-radius: 5px;
            margin: 0 5px 0 0;
          }
        }
      }
    }
    .bold_ {
      font-weight: bold;
    }
    .con_ {
      margin-top: 110px;
      // padding:0 170px;
      background-color: #f7f7f7;
      color: #000;
      font-size: 14px;
      &.w_74 {
        padding: 0;
      }
      .con1_ {
        padding: 126px 0 116px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin: 0 auto;
        img {
          border-radius: 10px;
          width: 41%;
          height: 377px;
          // top:50%;
          // transform:translateY(-50%);
          // left:0;
          object-fit: cover;
        }
        .content_ {
          display: flex;
          align-items: initial;
          justify-content: space-between;
          position: relative;
          // left: 110px;
          // width:740px;
          width: 55%;
          padding: 26px 0;
        }
        .left_ {
          display: flex;
          align-items: initial;
          justify-content: center;
          flex-direction: column;
          text-align: left;
          width: 100%;
          .tit_ {
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            letter-spacing: 1px;
            margin-bottom: 16px;
          }
          .des_ {
            font-size: 13px;
            line-height: 22px;
            text-align: left;
          }
          .flex_ {
            align-items: initial;
            margin-top: 30px;
            justify-content: left;
          }
          .flex_i {
            &:first-child {
              width: 224px;
            }
            &:nth-child(2) {
              width: 247px;
            }
            &:nth-child(3) {
              width: 295px;
            }
            span {
              padding: 6px;
              display: flex;
              align-items: center;
              &:first-child {
                font-size: 18px;
              }
              font-size: 13px;
              .drop {
                background-color: #00a63c;
                margin-left: 0;
              }
            }
          }
          p:last-child {
            width: 110px;
            height: 37px;
            line-height: 28px;
            border: 1px solid #000;
            margin-top: 30px;
            a {
              color: #000;
              width: 100%;
              display: block;
              display: flex;
              align-items: center;
              justify-content: center;
              display: block;
              height: 37px;
              line-height: 37px;
            }
            .jiantou_ {
              width: 7px;
              height: 14px;
              display: inline-block;
              background: url(../../assets/medilink/sanjiao.png) center center
                no-repeat;
              background-size: 7px 7px;
              margin-left: 10px;
              top: 2px;
              position: relative;
            }
            &:hover {
              background-color: #00a63c;
              border-color: #00a63c;
              a {
                color: #fff;
              }
              .jiantou_ {
                background: url(../../assets/medilink/sanjiao2.png) center
                  center no-repeat;
                background-size: 7px 7px;
              }
            }
          }
        }
        .right_ {
          width: 53px;
          height: 43px;
          background: url(../../assets/medilink/yinhao.png) center center
            no-repeat;
          background-size: 53px 43px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
    .bgtit_ {
      font-size: 66px;
      font-weight: 600;
      font-family: "Microsoft YaHei";
      color: rgba(252, 252, 252, 1);
      position: relative;
      margin-top: 115px;
      text-align: center;
      height: 70px;
      line-height: 70px;
      .tit_ {
        font-family: "Microsoft YaHei";
        position: absolute;
        color: #000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .join_ {
      display: block;
      width: 170px;
      height: 40px;
      line-height: 38px;
      text-align: center;
      border: 1px solid #000;
      margin: 30px auto 0;
      font-weight: bold;
      &:hover {
        color: #fff;
        a {
          color: #fff;
        }
        background-color: #00a63c;
        border-color: #00a63c;
      }
      a {
        color: #000;
      }
    }
    .con_2 {
      margin-top: 110px;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      &.con_3 .con_item {
        width: 20%;
        height: 100%;
        padding-top: 20%;
        & > span {
          padding: 32px 0 19px;
        }
        .mask_ {
          background-color: rgba(0, 166, 60, 0.9);
        }
        .mask_more {
          background-color: rgba(0, 0, 0, 0.5);
        }
        &:last-child {
          cursor: pointer;
          transition: all 0.6s;
        }
        &:last-child:hover {
          transform: scale(1.1);
        }
      }
      .con_item {
        width: 25%;
        padding-top: 25%;
        position: relative;
        &:hover {
          .mask_ {
            display: block;
          }
          & > span {
            display: none;
          }
        }
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          right: 0;
        }
        & > span {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          font-size: 14px;
          color: #fff;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 32px 0 19px;
          text-align: center;
        }
        .mask_ {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          z-index: 5;
          display: none;
          span {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateY(-50%);
            text-align: center;
            color: #fff;
          }
          .m_con {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateY(-50%);
            text-align: center;
            color: #fff;
            padding: 0 40px;
            p:first-child {
              font-size: 15px;
              margin-bottom: 10px;
              font-weight: bold;
            }
            p:last-child {
              font-size: 10px;
              margin-top: 20px;
            }
            .fs_15 {
              font-size: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              .jiantou2_ {
                width: 22px;
                height: 14px;
                background: url(../../assets/medilink/jiantourb.png) center
                  center no-repeat;
                background-size: 22px 14px;
                display: inline-block;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
    .con_4 {
      margin-top: 110px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 78px;
      .con_item1 {
        width: 18%;
        height: 11.38%;
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 100%;
          box-shadow: 0px 8px 25px 0px rgba(232, 232, 232, 0.4);
        }
        &:hover img {
          box-shadow: 0px 8px 25px 0px rgba(232, 232, 232, 0.7);
        }
      }
    }
  }
}
.label_p {
  display: flex;
  color: #fff;
  border-radius: 17px;
  background: rgba(0, 0, 0, 0.8);
  .label_l {
    font-size: 17px;
    font-weight: bold;
    .drop {
      width: 7px;
      height: 7px;
      border-radius: 4px;
      background-color: #00a63c;
      display: inline-block;
    }
  }
  .label_r {
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    .gray_ {
      font-size: 14px;
    }
  }
}
.fixbg1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  .ant-spin-spinning {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    left: 0;
    margin: auto;
  }
}
.news_ {
  position: fixed;
  right: 0;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 500px;
  background-color: #fff;
  padding: 30px 30px;
  z-index: 999;
  .close_ {
    width: 25px;
    height: 25px;
    background-color: #fff;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    background: url(../../assets/medilink/about/close.png) center center
      no-repeat;
    background-size: 25px 25px;
    border-radius: 20px;
    cursor: pointer;
  }
  p {
    color: #333;
    text-indent: 2em;
    line-height: 20px;
    font-size: 15px;
    text-align: justify;
  }
}
</style>
